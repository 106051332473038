import * as React from 'react'
import Layout from '../../components/layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { graphql } from 'gatsby'
import {Grid} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
const StaffEntry = ({data}) => {
  return (
    <Layout pageTitle="Super Cool Blog Posts">
      <CssBaseline />
      <Grid container spacing={0}>
        <Grid item xs={1}/>
        <Grid item xs={10}>
      <h1>{data.mdx.frontmatter.name}</h1>
      <h4>{data.mdx.frontmatter.subhead}</h4>
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
        </Grid>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        name       
      }
      body
    }
  }
`
export default StaffEntry

